import { IonCol, IonContent, IonGrid, IonPage, IonRow, IonText } from '@ionic/react'
import { useCallback, useMemo } from 'react'
import { LocationWithAddress } from '../../shared-qurani-server-client/models/ExternalModels'
import { PrayerCalcMethodCode } from '../../shared-qurani-server-client/modules/prayer/PrayersUtils'
import { usePrayerTimesScreenLogic } from '../../shared-qurani-server-client/modules/prayer/PrayerTimesHooks'
import { useDebouncedState } from '../../shared-ts/react/hooks/Hooks'
import { useStrings } from '../../shared-ts/services/LangService'

export function PrayerTimes() {
    const calcMethodCode: PrayerCalcMethodCode = 'mwl'
    const selectedLocation: undefined | LocationWithAddress = undefined //= useSubjectAsState(locationService.selectedLocation)
    const str = useStrings()

    const location = useMemo(
        () =>
            selectedLocation ?? {
                // california
                // latitude: 37.78825,
                // longitude: -122.4324,
                latitude: 8.4,
                longitude: 124.6,
                address: undefined,
                countryCode: undefined,
                checkIfDetected: undefined,
            },
        [selectedLocation]
    ) // separate this since we can't directly create an object outside

    const debouncedLocation = useDebouncedState(location, 500) // high debounce time since geocoder is rate limited

    // const { address, countryCode } = useAddressForLocation(debouncedLocation) ?? {}
    const address = 'Gran Europa, Lumbia, Cagayan de Oro City, Misamis Oriental, Philippines'
    const countryCode = 'PH'

    const {
        times,
        current,
        next,
        remainingTimes,
        currentRemainingTime,
        dateString,
        isLoading,
        setLoading,
        date,
        calcMethod,
        selectedTimeZone,
        setDate,
        isExpandedMap,
        setExpandedMap,
        inflectionPoints,
        setInflectionPoints,
        isCalcMethodsExpanded,
        setCalcMethodsExpanded,
        todayString,
    } = usePrayerTimesScreenLogic(
        calcMethodCode,
        // useCallback(() => locationService.updateLastKnownLocation(str), [str]),
        useCallback(async () => {}, []),
        countryCode,
        location,
        debouncedLocation
    )

    return (
        <IonPage>
            {/* <IonHeader>
                <IonToolbar>
                    <IonTitle>Prayer Times</IonTitle>
                </IonToolbar>
            </IonHeader> */}
            <IonContent fullscreen>
                {/* <IonHeader collapse='condense'>
                    <IonToolbar>
                        <IonTitle size='large'>Prayer Times</IonTitle>
                    </IonToolbar>
                </IonHeader> */}
                <IonGrid>
                    <IonRow class='ion-margin-top ion-align-items-center'>
                        <IonCol class='ion-text-center'>
                            <IonRow>
                                <IonCol>
                                    <IonText style={{ fontWeight: 'bold', fontSize: '40px' }}>
                                        {current.displayName}
                                    </IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonText style={{ fontSize: '30px' }}>
                                        {current.timeFormatted}
                                    </IonText>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                        <IonCol class='ion-text-center'>
                            <IonText style={{ fontWeight: 'bold', fontSize: '30px' }}>
                                {currentRemainingTime}
                            </IonText>
                        </IonCol>
                        <IonCol class='ion-text-center'>
                            <IonRow>
                                <IonCol>
                                    <IonText style={{ fontWeight: 'bold', fontSize: '40px' }}>
                                        {next.displayName}
                                    </IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonText style={{ fontSize: '30px' }}>
                                        {next.timeFormatted}
                                    </IonText>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                    {/* <IonRow>
                        <IonCol>
                            <IonButton
                                fill='outline'
                                onClick={() => {
                                    setPrayerTimesExpanded(x => !x)
                                }}
                                expand='block'
                            >
                                Prayer Times
                                <IonIcon
                                    icon={prayerTimesExpanded ? chevronUp : chevronDown}
                                    slot='end'
                                ></IonIcon>
                            </IonButton>
                        </IonCol>
                    </IonRow> */}
                    {times.map((prayer, prayerIndex) => (
                        <IonRow key={prayer.displayName}>
                            <IonCol>
                                <IonText>{prayer.displayName}</IonText>
                            </IonCol>
                            <IonCol class='ion-text-center'>
                                <IonText>{remainingTimes[prayerIndex]}</IonText>
                            </IonCol>
                            <IonCol class='ion-text-right'>
                                <IonText>{prayer.timeFormatted}</IonText>
                            </IonCol>
                        </IonRow>
                    ))}

                    <IonRow class='ion-margin-top'>
                        {/* class='ion-text-center' */}
                        <IonCol>
                            <IonText>
                                {dateString} {dateString === todayString ? '(Today)' : ''}
                            </IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        {/*  class='ion-text-center' */}
                        <IonCol>
                            <IonText>{address}</IonText>
                        </IonCol>
                    </IonRow>
                    {/* <IonRow>
                        <IonCol>
                            <IonButton fill='clear' color='dark'>
                                Refresh Location
                            </IonButton>
                        </IonCol>
                    </IonRow> */}
                </IonGrid>
            </IonContent>
        </IonPage>
    )
}
