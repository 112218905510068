// export function MuslimWorldLeague(): CalculationParameters
// export function Egyptian(): CalculationParameters
// export function Karachi(): CalculationParameters
// export function UmmAlQura(): CalculationParameters
// export function Dubai(): CalculationParameters
// export function MoonsightingCommittee(): CalculationParameters
// export function NorthAmerica(): CalculationParameters
// export function Kuwait(): CalculationParameters
// export function Qatar(): CalculationParameters
// export function Singapore(): CalculationParameters
// export function Other(): CalculationParameters

import { CalculationMethod, CalculationParameters } from 'adhan'

export type PrayerCalcMethodCode =
    | 'mwl'
    | 'egypt'
    | 'karachi'
    | 'ummalqura'
    | 'dubai'
    | 'msc'
    | 'america'
    | 'kuwait'
    | 'qatar'
    | 'singapore'
export const prayerCalcMethods: { code: PrayerCalcMethodCode; title: string }[] = [
    {
        code: 'mwl',
        title: 'Muslim World League',
    },
    {
        code: 'egypt',
        title: 'Egypt',
    },
    {
        code: 'karachi',
        title: 'Karachi',
    },
    {
        code: 'ummalqura',
        title: 'Umm Al-Qura',
    },
    {
        code: 'dubai',
        title: 'Dubai',
    },
    {
        code: 'msc',
        title: 'Moonsigting Commitee',
    },
    {
        code: 'america',
        title: 'North America',
    },
    {
        code: 'kuwait',
        title: 'Kuwait',
    },
    {
        code: 'qatar',
        title: 'Qatar',
    },
    {
        code: 'singapore',
        title: 'Singapore',
    },
]

export function getCalcMethodForCode(code: PrayerCalcMethodCode): CalculationParameters {
    switch (code) {
        case 'mwl':
            return CalculationMethod.MuslimWorldLeague()
        case 'egypt':
            return CalculationMethod.Egyptian()
        case 'karachi':
            return CalculationMethod.Karachi()
        case 'ummalqura':
            return CalculationMethod.UmmAlQura()
        case 'dubai':
            return CalculationMethod.Dubai()
        case 'msc':
            return CalculationMethod.MoonsightingCommittee()
        case 'america':
            return CalculationMethod.NorthAmerica()
        case 'kuwait':
            return CalculationMethod.Kuwait()
        case 'qatar':
            return CalculationMethod.Qatar()
        case 'singapore':
            return CalculationMethod.Singapore()
    }
}
