// subscribed to by the platform implementations
// export const onPlatformLogInfo = new Subject<{ message: string; params?: unknown }>()

// export function logInfo(message: string, params?: unknown) {
//     onPlatformLogInfo.next({ message, params })
//     console.log(message, JSON.stringify(params))
// }

export function logDebug(msg: unknown) {
    if (isProduction()) {
        return
    }
    console.log(msg)
}

export function isProduction() {
    return process.env.REACT_APP_IS_DEBUG !== 'true'
}
