import { CalculationParameters, PrayerTimes, Qibla } from 'adhan'
import moment, { Moment } from 'moment-timezone'
import {
    CoordinatesSimple,
    getAbsoluteSecondsFromDayStart,
    hoursToSeconds,
    roundToDecimalPlaces,
    secondsToHoursDecimal,
} from '../../../shared-ts'

/**
 * Will advance nextSeconds by 1 day if it's lesser than the current or previous seconds
 * because this will mean their days are different
 */
export function adjustNextSeconds(currentSeconds: number, nextSeconds: number) {
    return nextSeconds + (nextSeconds > currentSeconds ? 0 : hoursToSeconds(24))
}

export type PrayerTimesParams = {
    coordinates: CoordinatesSimple
    date: Date
    calcMethod: CalculationParameters
}

export type TimezoneCalcParams = { name?: string | undefined; offset?: number | undefined }

export function getAdjustedPrayerTime(date: Date, timezone: TimezoneCalcParams | undefined) {
    let time = moment(date)
    if (timezone?.name) {
        time = time.tz(timezone.name)
    } else if (timezone?.offset !== undefined) {
        time = time.utcOffset(timezone.offset * 60)
    }
    return time
}

function getPrayerTimeForIndex(times: PrayerTimes, index: number) {
    switch (index) {
        case 0:
            return times.fajr
        case 1:
            return times.sunrise
        case 2:
            return times.dhuhr
        case 3:
            return times.asr
        case 4:
            return times.maghrib
        case 5:
            return times.isha
        default:
            throw new Error('Invalid prayer time index')
    }
}

export function getQiblaDegreesFromNorth(coordinates: CoordinatesSimple) {
    return Qibla(coordinates)
}

export type PrayerInflectionPoint = {
    timeFormatted: string
    isHigh: boolean
    date: Moment
}
export function getInflectionPoints(
    startDate: Moment,
    prayerIndex: number,
    params: PrayerTimesParams,
    timezone: TimezoneCalcParams | undefined
) {
    const arr: PrayerInflectionPoint[] = []

    const format = 'MM-DD'
    const startDayOfMonth = startDate.format(format)

    const startPrayerTime = getPrayerTimeForIndex(
        new PrayerTimes(params.coordinates, startDate.toDate(), params.calcMethod),
        prayerIndex
    )
    let previous: {
        hoursDecimal: number
        date: moment.Moment
        prayerTime: Date
    } = {
        hoursDecimal: roundToDecimalPlaces(
            secondsToHoursDecimal(getAbsoluteSecondsFromDayStart(moment(startPrayerTime), true)),
            2
        ),
        date: startDate,
        prayerTime: startPrayerTime,
    }
    let wasPreviousIncreasing: boolean | 'same' = 'same'
    let i = 1

    let maxDate: Moment | undefined
    let minDate: Moment | undefined

    while (true) {
        const date = moment(startDate).add(i, 'day')
        const formatted = date.format(format)
        const prayerTimes = new PrayerTimes(params.coordinates, date.toDate(), params.calcMethod)
        const prayerTime = getPrayerTimeForIndex(prayerTimes, prayerIndex)
        const hoursDecimal = roundToDecimalPlaces(
            secondsToHoursDecimal(getAbsoluteSecondsFromDayStart(moment(prayerTime), true)),
            2
        )

        const currentlyIncreased =
            hoursDecimal === previous.hoursDecimal ? 'same' : hoursDecimal > previous.hoursDecimal

        if (
            currentlyIncreased !== 'same' &&
            (wasPreviousIncreasing !== 'same' || i > 1) &&
            wasPreviousIncreasing !== currentlyIncreased
        ) {
            const previousTime = moment(getAdjustedPrayerTime(previous.prayerTime, timezone))
            arr.push({
                timeFormatted: previousTime.format('h:mm A'),
                isHigh: currentlyIncreased === false,
                date: previous.date,
            })

            if (!maxDate || previousTime > maxDate) {
                maxDate = previousTime
            }
            if (!minDate || previousTime < minDate) {
                minDate = previousTime
            }
        }

        if (formatted === startDayOfMonth) {
            break
        }

        previous = {
            hoursDecimal,
            date,
            prayerTime,
        }

        if (currentlyIncreased !== 'same') {
            wasPreviousIncreasing = currentlyIncreased
        }

        i++
    }

    return { inflectionPoints: arr, maxDate, minDate }
}
