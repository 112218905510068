import numeral from 'numeral'

export const formatUtils = {
    withCommas(n: number) {
        // return n.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
        return n.toLocaleString('en-US', { minimumFractionDigits: 2 })
    },
    formatBytes(bytes: number, decimals = 2): string {
        if (bytes === 0) return '0 Bytes'

        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

        const i = Math.floor(Math.log(bytes) / Math.log(k))

        return parseFloat((bytes / k ** i).toFixed(dm)) + ' ' + sizes[i]
    },

    secondsToHoursMinutesSeconds(
        totalSeconds: number
        // alwaysPrependWithZero?: boolean
    ): string {
        const hours = Math.floor(totalSeconds / 3600)
        const remainingSeconds = totalSeconds - hours * 3600
        const minutes = Math.floor(remainingSeconds / 60)
        const seconds = Math.floor(remainingSeconds - minutes * 60)
        const hoursStr = hours === 0 ? '' : `${this.pad(hours, 2)}:`
        return `${hoursStr}${this.pad(minutes, 2)}:${this.pad(seconds, 2)}`
    },
    pad(n: number, width: number): string {
        const prefix = '0'
        const str = n + ''
        return str.length >= width ? str : new Array(width - str.length + 1).join(prefix) + str
    },
}

// // TODO detect locale
// const currencyFormatter = new Intl.NumberFormat('en-US', {
//     style: 'currency',
//     currency: 'PHP',
//     // These options are needed to round to whole numbers if that's what you want.
//     //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
//     //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
// })
export function formatAsCurrency(num: number) {
    // TODO detect currency sign or have an array of pairs
    return numeral(num).format('0,0[.]00')
}
