import { useEffect, useMemo, useRef, useState } from 'react'
import { BehaviorSubject, Observable } from 'rxjs'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { v4 as uuid } from 'uuid'
import { Action1, StringMap } from '../../types'
import {
    arrayToDictionary,
    handleErrorSilently,
    logDebug,
    throttleTimeLeadingAndTrailing,
} from '../../utils'

// export function useOnMount(...actions: Action0[]) {
//     useEffect(() => {
//         for (const action of actions) {
//             action()
//         }
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [])
// }

// export function useOnUnmount(...actions: MutableRefObject<Action0>[]) {
//     const actionsRef = useRef(actions)
//     useEffect(
//         () => () => {
//             for (const action of actionsRef.current) {
//                 action.current()
//             }
//         },
//         []
//     )
// }

export function useCancelMap() {
    const cancelMapRef = useRef<StringMap<boolean>>({})
    const tokenMapByGroup = useRef<StringMap<string>>({})
    return useMemo(
        () => ({
            // getNewTokenForGroup: useCallback((group: string) => {
            //     const token = uuid()
            //     tokenMapByGroup.current[group] = token
            //     return token
            // }, []),
            cancelCurrentAndGetNewTokenForGroup: (group: string) => {
                const currentToken = tokenMapByGroup.current[group]
                if (currentToken) {
                    cancelMapRef.current[currentToken] = true
                }

                const token = uuid()
                tokenMapByGroup.current[group] = token
                return token
            },
            isTokenCanceled: (token: string) => cancelMapRef.current[token],
        }),
        []
    )
}

/**
 * Use this if function is used inside an effect
 * or if it's used as a callback inside another function (or in an async function) since local functions will be outdated.
 * Use the useCallback hook if function is directly assigned as a prop.
 */
export function useFunctionRef<T>(param: T) {
    const ref = useRef(param)
    ref.current = param
    return ref
}

export function useSubjectAsState<T>(subject: BehaviorSubject<T>) {
    const initialState = subject.value
    const [state, setState] = useState(initialState)
    useEffect(() => {
        const d = subject.pipe(distinctUntilChanged()).subscribe(setState)
        return () => {
            d.unsubscribe()
        }
    }, [subject])
    return state
}
export function useObservableAsState<T>(subject: Observable<T | undefined>) {
    const initialState = undefined
    const [state, setState] = useState<T | undefined>(initialState)
    useEffect(() => {
        const d = subject.pipe(distinctUntilChanged()).subscribe(setState)
        return () => {
            d.unsubscribe()
        }
    }, [subject])
    return state
}
// export function useMappedSubjectAsState<T, R>(subject: BehaviorSubject<T>, mapperParam: () => R) {
//     // const param = subject.value

//     // const oldValueRef = useRef(mapperParam())
//     // const oldParamRef = useRef(param)

//     // if (oldParamRef.current !== param) {
//     //     oldParamRef.current = param
//     //     oldValueRef.current = mapperParam()
//     // }

//     // return oldValueRef.current

//     const mapperRef = useFunctionRef(mapperParam)

//     const [state, setState] = useState(mapperRef.current) // pass the function

//     useEffect(() => {
//         const d = subject
//             .pipe(
//                 distinctUntilChanged(),
//                 map(() => mapperRef.current())
//             )
//             .subscribe(setState)
//         return () => {
//             d.unsubscribe()
//         }
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [subject])
//     return state
// }

export function useStateAsObservable<T>(param: T) {
    const obsRef = useRef(new BehaviorSubject(param))

    useEffect(() => {
        obsRef.current.next(param)
    }, [param])

    return obsRef.current
}

export function useObservable<T>(obs: Observable<T>, callback: Action1<T>) {
    useEffect(() => {
        const d = obs.subscribe(callback)
        return () => {
            d.unsubscribe()
        }
    }, [callback, obs])
}
// export function useMounted() {
//     const isMountedRef = useRef(true)
//     useEffect(() => {
//         isMountedRef.current = true
//         return () => {
//             isMountedRef.current = false
//         }
//     }, [])
//     // return isMountedRef.current
//     return isMountedRef
// }
// export function useNotMounted() {
//     return !useMounted()
// }

export function useMountedRef() {
    const isMounted = useRef(true)
    // eslint-disable-next-line arrow-body-style
    useEffect(() => {
        return () => {
            isMounted.current = false
        }
    }, [])
    return isMounted
}

export function useDebouncedState<T>(param: T, durationMS: number) {
    const obs = useRef(new BehaviorSubject(param)).current
    const [state, setState] = useState(param)

    useEffect(() => {
        const d = obs.pipe(debounceTime(durationMS)).subscribe(param => {
            setState(param)
        })

        return () => {
            d.unsubscribe()
        }
    }, [durationMS, obs])

    useEffect(() => {
        obs.next(param)
    }, [obs, param])
    return state
}

/**
 * Throttle to improve responsiveness. Debounce if we expect multiple
 */
export function useThrottledState<T>(param: T, durationMS: number) {
    const obs = useRef(new BehaviorSubject(param)).current
    const [state, setState] = useState(param)

    useEffect(() => {
        const d = obs.pipe(throttleTimeLeadingAndTrailing(durationMS)).subscribe(param => {
            setState(param)
        })

        return () => {
            d.unsubscribe()
        }
    }, [durationMS, obs])

    useEffect(() => {
        obs.next(param)
    }, [obs, param])
    return state
}

export function useTimer(limitInclusive: number, refreshIndex: number, shouldPause: boolean) {
    const [second, setSecond] = useState(0)
    const shouldStop = shouldPause || second >= limitInclusive

    useEffect(() => {
        setSecond(0)
    }, [refreshIndex])

    useEffect(() => {
        if (shouldStop) {
            return
        }
        const token = setInterval(() => {
            setSecond(x => x + 1)
        }, 1000)

        return () => {
            clearInterval(token)
        }
    }, [shouldStop])
    return second
}

export function useCountdownTimer(
    maxInclusive: number,
    refreshIndex: number,
    shouldPause: boolean
) {
    const second = useTimer(maxInclusive, refreshIndex, shouldPause)
    return maxInclusive - second
}

// export function useMutable<T>(obj: T) {
//     const [state, setState] = useState(obj)

//     useEffect(() => {
//         setState(obj)
//     }, [obj])
//     return [state, setState] as const
// }

export function useMutableClone<T>(obj: T) {
    const [state, setState] = useState<T>((Array.isArray(obj) ? [...obj] : { ...obj }) as T)

    useEffect(() => {
        setState((Array.isArray(obj) ? [...obj] : { ...obj }) as T)
    }, [obj])
    return [state, setState] as const
}

export function useMemoAsync<T>(callback: () => Promise<T | undefined>) {
    const [value, setValue] = useState<T | undefined>(undefined)

    useEffect(() => {
        callback().then(setValue).catch(handleErrorSilently)
    }, [callback])

    return value
}
export function useNow(msInterval: number) {
    const [value, setValue] = useState<Date>(new Date())

    useEffect(() => {
        const token = setInterval(() => {
            setValue(new Date())
        }, msInterval)
        return () => {
            clearInterval(token)
        }
    }, [msInterval])

    return value
}
export function useSecondsSpentRef() {
    const secondsRef = useRef(0)
    useEffect(() => {
        const secondsInterval = 10
        const token = setInterval(() => {
            secondsRef.current += secondsInterval
            logDebug(`seconds spent: ${secondsRef.current}`)
        }, secondsInterval * 1000)
        return () => {
            clearInterval(token)
        }
    }, [])
    return secondsRef
}
// TODO should be a callback
// , getKey: Func1<T, string | number>
export function useArrayToDictionary<T extends { id: string }>(array: T[] | undefined) {
    return useMemo(() => (!array ? {} : arrayToDictionary(array, x => x.id)), [array])
}
// export function useArrayToDictionary<T>(array: T[] | undefined, getKey: Func1<T, string | number>) {
//     return useMemo(() => (!array ? {} : arrayToDictionary(array, getKey)), [array, getKey])
// }

// export function useOldValue<T>(value: T) {
//     const oldRef = useRef(undefined)
//     const old = oldRef.current

//     if(old !== value){

//     }

//     const currentRef = useRef(value)
//     const current = currentRef.current
// }
