import { PrayerTimes } from 'adhan'
import moment from 'moment'
import { useEffect, useMemo, useRef, useState } from 'react'
import {
    CoordinatesSimple,
    formatUtils,
    Func0,
    getAbsoluteSecondsFromDayStart,
    handleError,
    hoursToSeconds,
    NumberMap,
    secondsToHoursDecimal,
    Strings,
    throwIfNull,
    useMountedRef,
    useStrings,
} from '../../../shared-ts'
import { LocationWithAddress, PrayerTime, Timezone } from '../../models/ExternalModels'
import { getTimezonesForCoordinates } from '../../utils/Utilities'
import {
    adjustNextSeconds,
    getAdjustedPrayerTime,
    PrayerInflectionPoint,
    PrayerTimesParams,
    TimezoneCalcParams,
} from './PrayerService'
import { getCalcMethodForCode, PrayerCalcMethodCode } from './PrayersUtils'

export function useTimezonesForCountry(
    countryCode: string | undefined,
    location: CoordinatesSimple
) {
    return useMemo<Timezone[]>(
        () => getTimezonesForCoordinates(countryCode, location),
        [countryCode, location]
    )
}

function usePrayerTimes(
    params: PrayerTimesParams,
    str: Strings,
    timezone?: { name?: string | undefined; offset?: number | undefined }
) {
    const [nowAbsoluteSecondsFromDayStart, setNowAbsoluteSeconds] = useState(
        getAbsoluteSecondsFromDayStart(moment())
    )

    const currentAndNextSecondsRef = useRef(
        undefined as { current: number; next: number } | undefined
    )
    const { coordinates, date, calcMethod: calcParams } = params

    const times = useMemo<PrayerTime[]>(() => {
        const prayerTimes = new PrayerTimes(coordinates, date, calcParams)
        return [
            {
                displayName: str.prayer.prayerNames.fajr,
                ...getFormattedTime(prayerTimes.fajr, timezone),
            },
            {
                displayName: str.prayer.prayerNames.sunrise,
                ...getFormattedTime(prayerTimes.sunrise, timezone),
            },
            {
                displayName: str.prayer.prayerNames.dhuhr,
                ...getFormattedTime(prayerTimes.dhuhr, timezone),
            },
            {
                displayName: str.prayer.prayerNames.asr,
                ...getFormattedTime(prayerTimes.asr, timezone),
            },
            {
                displayName: str.prayer.prayerNames.maghrib,
                ...getFormattedTime(prayerTimes.maghrib, timezone),
            },
            {
                displayName: str.prayer.prayerNames.isha,
                ...getFormattedTime(prayerTimes.isha, timezone),
            },
        ]
    }, [calcParams, coordinates, date, str, timezone])

    const { current, next } = useMemo(() => {
        let current: PrayerTime | undefined
        let next: PrayerTime | undefined
        for (let i = 0; i < times.length; i++) {
            const currentItem = times[i]
            const nextItem = i === times.length - 1 ? times[0] : times[i + 1]

            const currentSeconds = currentItem.absoluteSeconds
            const nextSecondsAdjusted = adjustNextSeconds(currentSeconds, nextItem.absoluteSeconds)
            const nowSecondsAdjusted = adjustNextSeconds(
                currentSeconds,
                nowAbsoluteSecondsFromDayStart
                // getAbsoluteSecondsFromDayStart(moment()) // TODO monitor performance
            )

            if (nowSecondsAdjusted >= currentSeconds && nowSecondsAdjusted <= nextSecondsAdjusted) {
                current = currentItem
                next = nextItem
                currentAndNextSecondsRef.current = {
                    current: currentSeconds,
                    next: nextSecondsAdjusted,
                }
            }

            currentItem.nextAbsoluteSecondsAdjusted = nextSecondsAdjusted // side effect
        }

        return { current: throwIfNull(current), next: throwIfNull(next) }
    }, [nowAbsoluteSecondsFromDayStart, times])

    const { remainingTimes, currentRemainingTime } = useMemo(() => {
        let currentRemainingTime: string | undefined
        let currentIndex: number | undefined
        const remainingTimes: string[] = []
        for (let i = 0; i < times.length; i++) {
            const time = times[i]
            const nowSecondsAdjusted = adjustNextSeconds(
                time.absoluteSeconds,
                nowAbsoluteSecondsFromDayStart
            )
            let diffSeconds = time.nextAbsoluteSecondsAdjusted - nowSecondsAdjusted
            if (diffSeconds < 0) {
                // equivalent to ajdusting nextAbsoluteSeconds by 1 day
                diffSeconds += hoursToSeconds(24)
            }

            const remainingFormatted = formatUtils.secondsToHoursMinutesSeconds(diffSeconds)
            if (time === current) {
                currentRemainingTime = remainingFormatted
                currentIndex = i
            }

            const nextIndex = i === times.length - 1 ? 0 : i + 1
            remainingTimes[nextIndex] = `in ${remainingFormatted}`
        }

        remainingTimes[throwIfNull(currentIndex)] = 'active' // current prayer should have blank

        return { remainingTimes, currentRemainingTime: throwIfNull(currentRemainingTime) }
    }, [current, nowAbsoluteSecondsFromDayStart, times])

    useEffect(() => {
        const token = setInterval(() => {
            setNowAbsoluteSeconds(getAbsoluteSecondsFromDayStart(moment()))
        }, 1000)
        return () => {
            clearInterval(token)
        }
    }, [])

    return { times, current, next, remainingTimes, currentRemainingTime }
}

function getFormattedTime(
    prayerTime: Date,
    timezone: TimezoneCalcParams | undefined
): Omit<PrayerTime, 'displayName'> {
    const time = getAdjustedPrayerTime(prayerTime, timezone)
    // .subtract(2, 'hour')
    // .subtract(35, 'minute')
    // .subtract(40, 'second') // for testing
    const timeFormatted = time.format('h:mm A')
    const absoluteSeconds = getAbsoluteSecondsFromDayStart(time)
    const absoluteHoursDecimal = secondsToHoursDecimal(absoluteSeconds)

    return {
        timeFormatted,
        absoluteHoursDecimal,
        absoluteSeconds,
        nextAbsoluteSecondsAdjusted: -1,
    }
}

const dateFormat = 'MMM D, YYYY, ddd'
export function usePrayerTimesScreenLogic(
    calcMethodCode: PrayerCalcMethodCode,
    updateLocationCallback: Func0<Promise<void>>,
    countryCode: string,
    location: LocationWithAddress,
    debouncedLocation: LocationWithAddress
) {
    const [date, setDate] = useState(moment())
    const dateString = useMemo(() => date.format(dateFormat), [date])

    const [isLoading, setLoading] = useState(true)
    const [timezoneIndex, setTimezoneIndex] = useState(0)

    // const isPrayersExpanded = true
    // const calcMethodCode: PrayerCalcMethodCode = 'mwl'

    const str = useStrings()

    // const isNotMounted = useNotMounted()
    const isMountedRef = useMountedRef()

    const useDefaultTimezone = useMemo(
        () => true,
        // Restore this when location view is restored
        // return debouncedLocation.checkIfDetected?.()
        // [debouncedLocation]
        []
    )
    const timezones = useTimezonesForCountry(countryCode, debouncedLocation)
    const selectedTimeZone = useDefaultTimezone ? undefined : timezones[timezoneIndex]

    const calcMethod = useMemo(() => getCalcMethodForCode(calcMethodCode), [calcMethodCode])

    const { times, current, next, remainingTimes, currentRemainingTime } = usePrayerTimes(
        {
            coordinates: location,
            date: date.toDate(),
            calcMethod,
        },
        str,
        selectedTimeZone
    )

    const [isExpandedMap, setExpandedMap] = useState<NumberMap<boolean>>({})
    const [inflectionPoints, setInflectionPoints] = useState<NumberMap<PrayerInflectionPoint[]>>({})
    const [isCalcMethodsExpanded, setCalcMethodsExpanded] = useState(false)

    const todayString = moment().format(dateFormat)

    useEffect(() => {
        setTimezoneIndex(0)
    }, [timezones])

    useEffect(() => {
        if (isLoading) {
            updateLocationCallback()
                .catch(handleError)
                .finally(() => {
                    if (!isMountedRef.current) {
                        return
                    }
                    setLoading(false)
                })
                .catch()
        }
    }, [isLoading, isMountedRef, str, updateLocationCallback])

    return {
        times,
        current,
        next,
        remainingTimes,
        currentRemainingTime,
        dateString,
        isLoading,
        setLoading,
        date,
        calcMethod,
        selectedTimeZone,
        setDate,
        isExpandedMap,
        setExpandedMap,
        inflectionPoints,
        setInflectionPoints,
        isCalcMethodsExpanded,
        setCalcMethodsExpanded,
        todayString,
    }
}
