import moment from 'moment-timezone'
import {
    CoordinatesSimple,
    Deletable,
    findHighlightRanges,
    getNextQuranChapterId,
    getPreviousQuranChapterId,
    getTimezoneOffsetForLongitudeHours,
    HighlightRange,
    logDebug,
    Result,
    StringMap,
} from '../../shared-ts'
import { Hadith, HighlightedText, Timezone, VerseReference } from '../models'
import { WithInsertedFields } from '../models/models-firebase/Common'

export function isObjectDeleted(obj: unknown) {
    if ((obj as Deletable)?.isDeleted) {
        return true
    }

    return false
}

export function getHighlightRangesForTafsirLineItem(
    highlightWord: string | string[] | undefined,
    tafsirItem: { text: string; itemNbr: number },
    higlightedTextsResult: Result<StringMap<WithInsertedFields<HighlightedText>>>
): HighlightRange[] {
    const highlightRanges: HighlightRange[] = highlightWord
        ? [...findHighlightRanges(tafsirItem.text, highlightWord)] // initialize with the searched highlighted word
        : []

    // TODO: optimize this by using a map
    if (higlightedTextsResult.data) {
        // const range = rangesMap[getTafsirReferenceString(tafsirItem.reference)]
        // const range = higlightedTextsResult.data[getTafsirReferenceString(tafsirItem.reference)]
        // if (range) {
        //     highlightRanges.push({
        //         start: range.start,
        //         end: range.end,
        //         id: range.ref.id,
        //     })
        // }

        // get highlights for the current line item
        for (const key of Object.keys(higlightedTextsResult.data)) {
            const highlightedText = higlightedTextsResult.data[key]
            if (!highlightedText) {
                continue
            }
            if (highlightedText.reference.itemNbr === tafsirItem.itemNbr) {
                // Using the actual range here (which contains the object) will lead to
                // cyclic error in using JSON.stringify inside SelectableText
                highlightRanges.push({
                    start: highlightedText.start,
                    end: highlightedText.end,
                    id: highlightedText.ref.id,
                })
            }
        }
        // for (const range of higlightRangesResult.data) {
        //     if (range.obj.reference.itemNbr === tafsirItem.itemNbr) {
        //         // Using the actual range here (which contains the object) will lead to
        //         // cyclic error in using JSON.stringify inside SelectableText
        //         highlightRanges.push({
        //             start: range.start,
        //             end: range.end,
        //             id: range.id,
        //         })
        //     }
        // }
    }
    return highlightRanges
}

export function getHadithFullContentEn(hadith: Hadith) {
    return `${hadith.narratorTextEn.trim()}\n${hadith.contentEn}`.trim()
}

/**@deprecated Use getNextVerse in service utils */
export function getNextQuranVerseNbrOrSurahLegacy(
    currentChapterId: number,
    currentVerseNbr: number,
    currentSurahVerseCount: number
): VerseReference {
    logDebug({ currentSurahVerseCount })
    if (currentVerseNbr === currentSurahVerseCount) {
        return { verseNbr: 1, chapterId: getNextQuranChapterId(currentChapterId) }
    } else {
        return { verseNbr: currentVerseNbr + 1, chapterId: currentChapterId }
    }
}

/**@deprecated Use getPreviousVerse in service utils */
export function getPreviousQuranVerseNbrOrSurahLegacy(
    currentChapterId: number,
    currentVerseNbr: number,
    previousSurahVerseCount: number
): VerseReference {
    if (currentVerseNbr === 1) {
        return {
            verseNbr: previousSurahVerseCount,
            chapterId: getPreviousQuranChapterId(currentChapterId),
        }
    } else {
        return { verseNbr: currentVerseNbr - 1, chapterId: currentChapterId }
    }
}

export function getTimezonesForCoordinates(
    countryCode: string | undefined,
    location: CoordinatesSimple
): Timezone[] {
    const zonesForCountry = countryCode ? moment.tz.zonesForCountry(countryCode, true) : undefined

    if (zonesForCountry?.length) {
        return zonesForCountry
    }

    const offset = getTimezoneOffsetForLongitudeHours(location.longitude)
    // return [{ offset, name: `${offset >= 0 ? '+' : '-'}${offset} UTC` }]
    return [{ offset, name: undefined }] // we can't use above since it won't be reconized by moment
}

export function getTimezoneOffsetDisplay(offset: number) {
    return `${offset <= 0 ? '+' : '-'}${Math.round(Math.abs(offset / 60) * 100) / 100} UTC`
}

export function getTimezoneDisplay(timezone: Timezone) {
    return `${
        timezone.name === undefined ? getTimezoneOffsetDisplay(timezone.offset) : timezone.name
    } (${getTimezoneOffsetDisplay(timezone.offset)})`
}
